body,
#root,
.app {
	margin: 0;
	width: 100%;
}

/* non-selecting */
* {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;

	cursor: default;
}

button {
	cursor: pointer;
	font-size: 20pt;
}

.App {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.debug {
	display: none !important;
}

nav {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 20px;
}

.HUD {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin: auto;
	width: 80%;
	margin-top: 20px;
	margin-bottom: 10px;
}

.HUD * {
	margin: auto 20px;
	display: block;
}

.HUD h1 {
	font-size: 60pt;
}

.HUD h3 {
	font-size: 40pt;
}

main {
	display: flex;
	flex-direction: row;
	text-align: center;
	margin: 0 25px;
}

main div {
	margin: 10px auto;
}

button.UnitListing,
button.TerritoryListing {
	--color: black;
	background-color: white;
	color: var(--color);
	border: 5px solid var(--color);
	border-radius: 15px;
	padding: 3px 15px;
}

button.UnitListing span.value {
	font-weight: bold;
	border-radius: 15px;
	padding: 1px 5px;
	position: relative;
	background-color: var(--color);
	color: white;
	right: 8px;
}

.UnitShelf button.available {
	--color: limegreen;
}

.UnitShelf button:not(.available) {
	color: lightgray;
	--color: lightgray;
	cursor: default;
}

.UnitCart button {
	--color: red;
}

.UnitInventory button {
	--color: skyblue;
	cursor: default;
}

.TerritoryDisplay .owned button {
	--color: black;
	cursor: default;
}

.TerritoryDisplay .bordering button {
	--color: gray;
}

.TerritoryDisplay .disconnected button {
	--color: lightgray;
}
